var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "yqg-footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", [_vm._v("©2017 新余市瓴岳网络小额贷款有限公司")]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "copyright",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/recordQuery",
              rel: "noreferrer",
              target: "_blank",
            },
          },
          [_vm._v("\n            赣ICP备18002081号-1\n        ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "copyright",
            attrs: {
              href: "https://beian.mps.gov.cn/#/query/webSearch?code=36052102100041",
              rel: "noreferrer",
              target: "_blank",
            },
          },
          [
            _c("img", { attrs: { src: require("./img/icp.png") } }),
            _vm._v("\n            赣公网安备36052102100041\n        "),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "email-content" }, [
          _c("div", { staticClass: "email-title" }, [
            _vm._v("\n                邮箱：\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "email-list" }, [
            _c(
              "a",
              {
                staticClass: "email-item",
                attrs: { href: "mailto:developer@yangqianguan.com" },
              },
              [_vm._v("developer@yangqianguan.com")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "email-item",
                attrs: { href: "mailto:developer@fintopia.tech" },
              },
              [_vm._v("developer@fintopia.tech")]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }