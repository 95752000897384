/**
 * @Author: hongweihu
 * @Date: 2019-12-04 16:37:23.969
 * @Last Modified by: hongweihu
 * @Last Modified time: 2023-12-13 11:35:42
 */
import AboutUs from './component/about-us';
import Banner from './component/banner';
import YqgFooter from './component/yqg-footer';
import YqgHeader from './component/yqg-header';
import YqgLoan from './component/yqg-loan';

export default {
    name: 'Index',

    title: '新余市瓴岳网络小额贷款有限公司',

    head() {
        const content = '新余市瓴岳网络小额贷款有限公司于2017年11月24日成立，公司经营范围包括通过网络平台开展线上小额贷款业务等。';

        return {meta: [{name: 'description', content}]};
    },

    components: {
        YqgHeader,
        Banner,
        AboutUs,
        YqgLoan,
        YqgFooter
    }
};
