/**
 * @Author: hongwei
 * @Date: 2021-12-08 19:04:35.649
 * @Last Modified by: hongweihu
 * @Last Modified time: 2023-07-31 16:55:36
 */

export default {
    name: 'YqgLoan'
};
