/**
 * @Author: hongwei
 * @Date: 2021-12-08 19:13:16.428
 * @Last Modified by: hongweihu
 * @Last Modified time: 2023-12-13 19:10:45
 */

export default {
    name: 'YqgFooter'
};
