var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "yqg-loan" }, [
      _c("div", { staticClass: "container" }, [
        _c("span", { staticClass: "title" }, [_vm._v("洋钱罐借款")]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            "\n            基于互联网大数据的风控方式，运用数据挖掘、机器学习等建模方法，为尚未被传统征信覆盖或未被传统金融机构满足的人群提供完善的智能信贷服务。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("./img/logo.png") },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }