var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-us" }, [
      _c("div", { staticClass: "container" }, [
        _c("span", { staticClass: "title" }, [_vm._v("关于我们")]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            "\n            新余市瓴岳网络小额贷款有限公司于2017年11月24日成立。公司经营范围包括：通过网络平台开展线上小额贷款业务；\n            在新余市分宜县及市内周边县域开展线下小额贷款业务及权益类投资业务；\n            向金融机构融入资金和经省政府金融办批准的其他业务(依法须经批准的项目，经相关部门批准后方可开展经营活动)等。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n            我们贯彻“以人为本”的经营管理理念，实施人才战略，公司高级管理人员均具有长期从事银行信贷、投融资管理工作经验。我们以科学的管理制度，\n            高效的风险控制体系打造出一支高素质的专业经营团队。积极创新各类金融产品，扩大业务规模，为客户提供一体化的融资服务，为公司可持续的、健康的发展奠定良好的基础。\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }